// orientation: (portrait | landscape)

export const photos = () => {
  return [
    { url: "img/about_3.jpg", orientation: "landscape" },
    { url: "img/about_2.jpg", orientation: "portrait" },
    { url: "img/about_0.png", orientation: "portrait" },
    { url: "img/about_1.jpg", orientation: "landscape" },
  ];
};
